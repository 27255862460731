
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import { ShopWeeklyRunningReport } from '@/models/shopWeeklyRunningReport.model';
import { Vue, Component } from 'vue-property-decorator';

@Component({})
export default class ShopRunningReport extends Vue {
  pageLoading = true;

  loading = false;

  isRequesting = false;

  isExceptional = false;

  exceptionContent = ''; // 所选周期门店流量数据不足7天，不产出周报看板

  shopId!: number;

  form: { shopName: string; dates: string | undefined; dateRangeStr: string } =
    {
      shopName: '', // 极超音速飞弹~汽车服务1号店
      dates: undefined,
      dateRangeStr: '',
    };

  report: ShopWeeklyRunningReport | null = null;

  weekList: Array<{ label: string; value: string }> = [];

  @lazyInject(ShopService)
  shopService!: ShopService;

  extractDateRange(dateStr: string) {
    const today = new Date();
    const year = `${today.getFullYear()}年`;
    const arr = dateStr.split('-');
    if (arr[0].indexOf('年') < 0) {
      arr[0] = year + arr[0];
    }
    if (arr[1].indexOf('年') < 0) {
      arr[1] = year + arr[1];
    }

    const beginMonthIndex = arr[0].indexOf('月');
    const endMonthIndex = arr[1].indexOf('月');
    const beingDateIndex = arr[0].indexOf('日');
    const endDateIndex = arr[1].indexOf('日');
    const beginMonth = arr[0].substring(5, beginMonthIndex).padStart(2, '0');
    const endMonth = arr[1].substring(5, endMonthIndex).padStart(2, '0');
    const beginDate = arr[0]
      .substring(beginMonthIndex + 1, beingDateIndex)
      .padStart(2, '0');

    const endDate = arr[1]
      .substring(endMonthIndex + 1, endDateIndex)
      .padStart(2, '0');
    const beginYear = arr[0].substring(0, 4);
    const endYear = arr[1].substring(0, 4);

    return {
      beginYear,
      beginMonth,
      beginDate,
      endYear,
      endMonth,
      endDate,
    };
  }

  async handleChangeDate(e: string) {
    if (!e) {
      return;
    }
    this.form.dates = e;
    this.loading = true;
    this.isExceptional = false;
    this.report = null;
    const { beginYear, beginMonth, beginDate, endYear, endMonth, endDate } =
      this.extractDateRange(e);
    this.form.dateRangeStr = `${beginYear}年${beginMonth}月${beginDate}日 - ${endYear}年${endMonth}月${endDate}日`;
    const dates = `${beginYear}-${beginMonth}-${beginDate},${endYear}-${endMonth}-${endDate}`;

    await this.getRunningReportDetail(this.shopId, dates);
  }

  async getRunningReportDetail(shopId: number, dates?: string) {
    if (this.isRequesting) {
      return;
    }
    this.isRequesting = true;

    try {
      const res = await this.shopService.shopRunningReport(shopId, dates);
      if (res) {
        if (res.msg && res.msg !== '') {
          this.exceptionContent = res.msg;
          this.isExceptional = true;
        } else {
          this.isExceptional = false;
          this.exceptionContent = '';
        }

        if (res.report) {
          this.report = res.report;
        }
        this.$set(this.form, 'shopName', res.shopName);
        const list = res.dates.map((item) => {
          return {
            label: item,
            value: item,
          };
        });
        this.weekList = [...list];

        if (typeof dates === 'undefined') {
          this.$set(this.form, 'dates', list[0].value);
          const {
            beginYear,
            beginMonth,
            beginDate,
            endYear,
            endMonth,
            endDate,
          } = this.extractDateRange(list[0].value);
          this.form.dateRangeStr = `${beginYear}年${beginMonth}月${beginDate}日 - ${endYear}年${endMonth}月${endDate}日`;
        }

        if (this.pageLoading) {
          this.pageLoading = false;
        }
      }
      this.isRequesting = false;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.isRequesting = false;
    }
  }

  async created() {
    this.shopId = +this.$route.params.shopId;
    await this.getRunningReportDetail(this.shopId);
  }
}
